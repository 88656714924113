import "css/index.css"; // entrada
// import 'css/base/variables.css'; // variaveis
// import 'css/vendor/tippy-js.css';
// import 'tippy.js/animations/shift-away-subtle.css';




document
	.addEventListener("DOMContentLoaded", () =>
		document.body.style.opacity = 1
	)


